html, body, #root, .wrapper { height: 100%; }
.wrapper {
  display: grid;
  grid-template-rows: 65px auto;
}

main {
  &.editor { overflow: hidden; }
  grid-row-start:2;
  grid-row-end: last-line;
  position: relative;
  background: #F5F5F5;
  overflow: auto;
}

.tooltip {
  position: absolute;
  padding: 5px;
  height: 20px;
  font-size: 10px;
  background: #F2F5F7;
  border-radius: 0 5px 5px 0;
  color: #697881;
  box-shadow: 4px 2px 3px 0px #33333330;
  line-height: 10px;
  &:before {
    content: '';
    border-width: 10px;
    border-style: solid;
    border-color: transparent #F2F5F7 transparent transparent;
    position: absolute;
    top: 0;
    left: -20px;
    pointer-events: none;
  }
}

[data-tooltip] {
  position: relative;
  &:after {
    content: attr(data-tooltip);
    position: absolute;
    padding: 5px 5px;
    color: #697881;
    background: #F2F5F7;
    border-radius: 5px;

    transition: opacity .3s ease;
    // transition-delay: .7s;

    left: calc(100% + 3px);
    z-index: 10;
    height: 12px;
    font-size: 12px;
    line-height: 12px;
    box-shadow: 2px 2px 3px 2px #33333330;
    opacity: 0;
    pointer-events: none;
    width: auto;
    white-space: nowrap;
  }
  &:hover:after {
    opacity: 1;
    transition-delay: .5s;
  }
}
[data-shortcut] {
  position: relative;
  &:after {
    content: attr(data-shortcut);
    position: absolute;
    padding: 5px 5px;
    color: #697881;
    background: #F2F5F7;
    border-radius: 5px;

    transition: opacity .3s ease;
    // transition-delay: .7s;

    left: calc(100% + 3px);
    z-index: 10;
    height: 12px;
    font-size: 12px;
    line-height: 12px;
    box-shadow: 2px 2px 3px 2px #33333330;
    opacity: 0;
    pointer-events: none;
    width: auto;
    white-space: nowrap;
  }
  &:hover:after {
    opacity: 1;
    transition-delay: .5s;
  }
}

.ribbon {
  user-select: none;
  position: absolute;
  top: 5px;
  right: 10px;
  color: #74838C;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  bottom: 0;
  gap: 5px;
  z-index: 3;
  pointer-events: none;

  .ribbon-content {
    height: 100%;
    width: 290px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .item {
      max-height: 0;
      overflow: hidden;
      padding:  0;
      border-radius: 3px;
      background: white;
      flex: 0 1 auto;
      visibility: hidden;
      pointer-events: auto;

      &.active {
        height: auto;
        visibility: visible;

        margin-top: 5px;
        max-height: 500px;
        box-shadow: 5px 5px 6px 0px #33333330;
        padding: 5px;
        flex: 0 0 auto;
      }

      .ribbon-form {
        width: 100%;
        padding: 15px;
      }

      h2 {
        text-align: center;
        font-size: 1.2em;
        margin-top: 0;
      }

      h3 {
        text-align: center;
        font-size: 1em;
        margin-top: 5px;
        margin-bottom: 5px;
      }
      h3:first-child {
        margin-top: 0;
      }
      .row {
        display: flex;
        justify-content: center;
      }
      .half-row {
        display: inline-flex;
        width: 49%;
      }
    }

    .item:last-child {
      flex: 0 1 auto;
      overflow-y: auto;
      margin-bottom: 5px;
      // min-height: 300px;
    }

  }

  .ribbon-icons {
    border-radius: 3px;
    background-color: #F2F5F7;
    box-shadow: 1px 1px 5px 1px #0000002b;
    margin-top: 45px;
    user-select: none;
    z-index: 1;
    pointer-events: auto;
    .icon {
      position: relative;
      width: 60px;
      height: 60px;
      background: #F2F5F7 no-repeat center 5px;
      cursor: pointer;
      border-radius: 0 3px 3px 0;
      margin: 5px;
      text-transform: capitalize;
      line-height: 90px;
      text-align: center;
      font-size: 12px;
      font-weight: 500;
      color: #697881;
      background-size: 60%;
      &.active {
        background-color: white;
        box-shadow: -5px 4px 5px 1px #33333330;
      }

      &.active:before {
        content: "";
        position: absolute;
        top: 0;
        left: -10px;
        bottom: 0;
        width:  10px;
        background: white;
        // box-shadow: 8px 4px 6px 1px #33333330;
        // z-index: -1;
      }

      &.template { background-image: url(src/assets/ribbon-template.svg); }
      &.actions { background-image: url(src/assets/ribbon-actions.svg); }
      &.config { background-image: url(src/assets/ribbon-layers.svg); }
      &.edit { background-image: url(src/assets/ribbon-layers.svg); }
      &.view { background-image: url(src/assets/ribbon-view.svg); }
      &.inspect { background-image: url(src/assets/ribbon-inspect.svg); }
      &.checks { background-image: url(src/assets/ribbon-checks.svg); }
      &.layers { background-image: url(src/assets/ribbon-layers.svg); }
      &.jacquard { background-image: url(src/assets/ribbon-jacquard.svg); }
      &.scripts { background-image: url(src/assets/ribbon-scripts.svg); }
      &.settings { background-image: url(src/assets/ribbon-inspect.svg); }

      .content {
        position: relative;
        background:  white;
        height: 0;
        overflow: hidden;
        transition:  height .2s ease;
      }

      &:hover {
        .content {
          border-radius: 3px 0 3px 3px;

          height: 200px;
          width: 290px;
          right: 65px;
          left: -300px;
        }
      }
    }
  }
}

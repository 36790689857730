@use './stitch-icons.scss' as *;
.stitchbox {
  @include icons;
  max-height: 200px;
  gap: 15px 35px;

  button {
    width: 30px;
    height: 30px;
    outline: none;
    border-radius: 4px;
    border: none;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    cursor: pointer;
    justify-self: center;
    &:hover, &:active, .active { outline:none; }
  }

  >button {
    &:first-of-type {
      position: relative;
      width: 35px;
      height: 35px;
      margin-top: 5px;

      &.knit-both-icon,
      &.tuck-knit-icon,
      &.tuck-both-icon,
      &.knit-tuck-icon,
      &.drop-both-icon {
        width: 65px;
        &:hover, &.active {
          height: 40px;
          margin-bottom: -6px;
          left: 1px;
        }
      }
    }
    &.knit-both-icon,
    &.tuck-knit-icon,
    &.tuck-both-icon,
    &.knit-tuck-icon,
    &.drop-both-icon {
      &:hover, &.active {
        margin-top: -3px;
        margin-bottom: -3px;
      }
    }
  }

  &.faded {
    &:after {
      content: '';
      position: absolute;
      background: linear-gradient(transparent, white);
      bottom: 0;
      width: 100%;
      height: 60px;
      pointer-events: none;
    }
  }

  .stitch-selector {
    &:before {
      content: '';
      border-width: 10px;
      border-style: solid;
      border-color: transparent #F0F2F5 transparent transparent;
      position: absolute;
      left: -20px;
      top: calc(50% - 5px);
    }

    position: absolute;
    left: 110px;
    top: 50px;
    background-color: #F0F2F5;
    width: 620px;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    padding: 10px;
    gap: 10px;
    grid-row-start: 2;
    align-self: baseline;
    font-size: 11px;
    color: black;

    box-shadow: 0px 0px 4px 2px #0000002b;
    border-radius: 3px;

    >div { border-radius: 3px;}
    .right-label { font-size: 14px; }
    .basic-knitting {
      width: 600px;
      height: 150px;
      box-shadow: 0px 0px 4px 2px #0000002b;
      background: white;
      display: grid;
      grid-template-columns: 100px repeat(4, 1fr) 1.5fr repeat(3, 1fr)  30px;
      // grid-template-columns: 100px repeat(3, 1fr) 2fr repeat(4, 1fr) 30px;
      grid-template-rows: repeat(4, 30px);
      gap: 10px;
      .top-knit {
        grid-column-start: 2;
        grid-row-start: 1;
        align-self: end;
        justify-self: center;
      }

      .top-tuck {
        grid-column-start: 3;
        grid-row-start: 1;
        align-self: end;
        justify-self: center;
      }

      .top-split {
        grid-column-start: 4;
        grid-row-start: 1;
        align-self: end;
        justify-self: center;
      }

      .top-drop {
        grid-column-start: 5;
        grid-row-start: 1;
        align-self: end;
        justify-self: center;
      }

      .clear-label {
        grid-column-start: 6;
        grid-row-start: 1;
        // grid-column-end: span 2;
        align-self: end;
        justify-self: center;
      }

      .miss-label {
        grid-column-start: 6;
        grid-row-start: 4;
        justify-self: center;
      }

      .knit-both {
        grid-column-start: 7;
        grid-row-start: 1;
        align-self: end;
        justify-self: center;
      }

      .tuck-knit {
        grid-column-start: 8;
        grid-row-start: 1;
        align-self: end;
        justify-self: center;
      }

      .drop-both {
        grid-column-start: 9;
        grid-row-start: 2;
        justify-self: center;
      }

      .tuck-both {
        grid-column-start: 7;
        grid-row-start: 4;
        align-self: start;
        justify-self: center;
      }

      .knit-tuck {
        grid-column-start: 8;
        grid-row-start: 4;
        align-self: start;
        justify-self: center;
      }

      .first-row-label {
        grid-column-start: 1;
        grid-row-start: 2;
        align-self: center;
        text-align: right;
      }

      .second-row-label {
        grid-column-start: 1;
        grid-row-start: 3;
        align-self: center;
        text-align: right;
      }

      .right-label {
        grid-column-start: 10;
        grid-row-start: 1;
        grid-row-end: -1;
        writing-mode: vertical-rl;
        align-self: center;
        justify-self: center;
      }

      .rear-knit-icon {
        grid-column-start: 2;
        grid-row-start: 2;
      }

      .rear-tuck-icon {
        grid-column-start: 3;
        grid-row-start: 2;
      }

      .rear-split-icon {
        grid-column-start: 4;
        grid-row-start: 2;
      }

      .rear-drop-icon {
        grid-column-start: 5;
        grid-row-start: 2;
      }

      .front-knit-icon {
        grid-column-start: 2;
        grid-row-start: 3;
      }

      .front-tuck-icon {
        grid-column-start: 3;
        grid-row-start: 3;
      }
      .front-split-icon {
        grid-column-start: 4;
        grid-row-start: 3;
      }
      .front-drop-icon {
        grid-column-start: 5;
        grid-row-start: 3;
      }

      .clear-icon {
        position: relative;
        grid-column-start: 6;
        // grid-column-end: span 2;
        grid-row-start: 2;
        // grid-row-end: span 2;
        align-self: center;
        justify-self: center;
        background-repeat: no-repeat;
        background-position: center;
      }
      .miss-icon {
        grid-column-start: 6;
        grid-row-start: 3;
        align-self: center;
        justify-self: center;
        background-repeat: no-repeat;
        background-position: center;
      }

      .knit-both-icon {
        border-radius: 0;
        position: relative;
        grid-column-start: 7;
        grid-row-start: 2;
        background-repeat: no-repeat;
        background-position: center;
        justify-self: center;

      }

      .tuck-knit-icon {
        border-radius: 0;
        position: relative;
        grid-column-start: 8;
        grid-row-start: 2;
        background-repeat: no-repeat;
        background-position: center;
        justify-self: center;
      }

      .tuck-both-icon {
        border-radius: 0;
        position: relative;
        grid-column-start: 7;
        grid-row-start: 3;
        background-repeat: no-repeat;
        background-position: center;
        justify-self: center;

      }

      .knit-tuck-icon {
        border-radius: 0;
        position: relative;
        grid-column-start: 8;
        grid-row-start: 3;
        background-repeat: no-repeat;
        justify-self: center;
      }

      .drop-both-icon {
        border-radius: 0;
        position: relative;
        grid-column-start: 9;
        grid-row-start: 2;
        grid-row-end: span 2;
        align-self: center;
        justify-self: center;
        background-repeat: no-repeat;
        background-position: center;
      }
    }

    .transfer {
      width: 600px;
      height: 140px;
      box-shadow: 2px 2px 4px 2px #ddd;
      background: white;
      display: grid;
      grid-template-columns: 100px repeat(4, 1fr) 2fr repeat(4, 1fr) 30px;
      grid-template-rows: 30px 30px 30px 1fr;
      gap: 10px;

      .rack-left {
        grid-column-start: 2;
        grid-column-end: span 4;
        grid-row-start: 1;
        align-self: end;
        justify-self: center;
      }
      .rack-right {
        grid-column-start: 7;
        grid-column-end: span 4;
        grid-row-start: 1;
        align-self: end;
        justify-self: center;
      }

      .first-row-label {
        grid-column-start: 1;
        grid-row-start: 2;
        align-self: center;
        justify-self: right;
      }

      .second-row-label {
        grid-column-start: 1;
        grid-row-start: 3;
        align-self: center;
        justify-self: right;
      }
      .right-label {
        grid-column-start: 11;
        grid-row-start: 1;
        grid-row-end: -1;
        writing-mode: vertical-rl;
        align-self: center;
        justify-self: center;
      }

      .rl4-icon {
        grid-column-start: 2;
        grid-row-start: 2;
      }
      .rl3-icon {
        grid-column-start: 3;
        grid-row-start: 2;
      }
      .rl2-icon {
        grid-column-start: 4;
        grid-row-start: 2;
      }
      .rl1-icon {
        grid-column-start: 5;
        grid-row-start: 2;
      }

      .fl4-icon {
        grid-column-start: 2;
        grid-row-start: 3;
      }
      .fl3-icon {
        grid-column-start: 3;
        grid-row-start: 3;
      }
      .fl2-icon {
        grid-column-start: 4;
        grid-row-start: 3;
      }
      .fl1-icon {
        grid-column-start: 5;
        grid-row-start: 3;
      }

      .r0-icon {
        grid-column-start: 6;
        grid-row-start: 2;
      }
      .f0-icon {
        grid-column-start: 6;
        grid-row-start: 3;
      }

      .rr1-icon {
        grid-column-start: 7;
        grid-row-start: 2;
      }

      .rr2-icon {
        grid-column-start: 8;
        grid-row-start: 2;
      }

      .rr3-icon {
        grid-column-start: 9;
        grid-row-start: 2;
      }
      .rr4-icon {
        grid-column-start: 10;
        grid-row-start: 2;
      }

      .fr1-icon {
        grid-column-start: 7;
        grid-row-start: 3;
      }

      .fr2-icon {
        grid-column-start: 8;
        grid-row-start: 3;
      }

      .fr3-icon {
        grid-column-start: 9;
        grid-row-start: 3;
      }
      .fr4-icon {
        grid-column-start: 10;
        grid-row-start: 3;
      }
    }

    .knit-transfer {
      width: 550px;
      height: 140px;
      box-shadow: 2px 2px 4px 2px #ddd;
      background: white;
      display: grid;
      grid-template-columns: 100px repeat(4, 1fr) 2fr repeat(4, 1fr) 30px;
      grid-template-rows: 30px 30px 30px 1fr;
      gap: 10px;

      .rack-left {
        grid-column-start: 2;
        grid-column-end: span 4;
        grid-row-start: 1;
        align-self: end;
        justify-self: center;
      }

      .rack-right {
        grid-column-start: 7;
        grid-column-end: span 4;
        grid-row-start: 1;
        align-self: end;
        justify-self: center;
      }

      .first-row-label {
        grid-column-start: 1;
        grid-row-start: 2;
        align-self: center;
        justify-self: right;
        text-align: right;
      }

      .second-row-label {
        grid-column-start: 1;
        grid-row-start: 3;
        align-self: center;
        justify-self: right;
        text-align: right;
      }
      .right-label {
        grid-column-start: 11;
        grid-row-start: 1;
        grid-row-end: -1;
        writing-mode: vertical-rl;
        align-self: center;
        justify-self: center;
      }

      .rfl-4-icon {
        grid-column-start: 2;
        grid-row-start: 2;
      }
      .rfl-3-icon {
        grid-column-start: 3;
        grid-row-start: 2;
      }
      .rfl-2-icon {
        grid-column-start: 4;
        grid-row-start: 2;
      }
      .rfl-1-icon {
        grid-column-start: 5;
        grid-row-start: 2;
      }

      .frl-4-icon {
        grid-column-start: 2;
        grid-row-start: 3;
      }
      .frl-3-icon {
        grid-column-start: 3;
        grid-row-start: 3;
      }
      .frl-2-icon {
        grid-column-start: 4;
        grid-row-start: 3;
      }
      .frl-1-icon {
        grid-column-start: 5;
        grid-row-start: 3;
      }

      .down-icon {
        grid-column-start: 6;
        grid-row-start: 2;
      }
      .up-icon {
        grid-column-start: 6;
        grid-row-start: 3;
      }

      .rfr-1-icon {
        grid-column-start: 7;
        grid-row-start: 2;
      }

      .rfr-2-icon {
        grid-column-start: 8;
        grid-row-start: 2;
      }

      .rfr-3-icon {
        grid-column-start: 9;
        grid-row-start: 2;
      }
      .rfr-4-icon {
        grid-column-start: 10;
        grid-row-start: 2;
      }

      .frr-1-icon {
        grid-column-start: 7;
        grid-row-start: 3;
      }

      .frr-2-icon {
        grid-column-start: 8;
        grid-row-start: 3;
      }

      .frr-3-icon {
        grid-column-start: 9;
        grid-row-start: 3;
      }
      .frr-4-icon {
        grid-column-start: 10;
        grid-row-start: 3;
      }
    }
  }
}

.canvas-tooltip {
    position: absolute;
    border-radius: 5px;
    display: none;
    background-color: white;
    z-index: 3;
    padding: 5px;
    box-shadow: 4px 2px 3px 0px #33333330;
    font-size: 14px;
    min-width: 150px;

    .tooltip-head {
        color: white;
        background-color: #97A7B5;
        text-align: center;
        font-weight: bold;
    }
}

.mode-selector {
  justify-content: center;
  flex-grow: 2;
  margin: 0 20px;
  height: 65px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  background-clip: border-box;
  position: relative;

  &.shape { background-position: calc(50% - 63.5px) 8px; }
  &.pattern { background-position: calc(50% - 32.5px) 8px; }
  &.stitch { background-position: calc(50% + 29.5px) 8px; }

  button:focus-visible {
    outline: none;
  }
  .item {
    display: block;
    cursor: pointer;
    border-radius: 0 0 10px 0;

    width: 52px;
    height: 55px;

    padding: 0;
    border: none;

    position: relative;
    margin: 0 5px;

    background-position: center;
    background-size: 30px;
    background-repeat: no-repeat;
    &.active {
      background-color: #444;
      border-radius: 5px 5px 0 0;
      position: relative;
      box-shadow: inset 0 3px 9px -1px #252525;
      &::after {
        background-image: url(src/assets/mode-selected.svg);
        background-position: center;
        background-repeat: no-repeat;
        content: '';
        position: absolute;
        top: 0;
        left: -8px;
        width: 70px;
        height: 55px;
      }
    }


  }

  .shape {
      background-image: url(src/assets/mode-shape.svg);
  }

  .pattern {
      background-image: url(src/assets/mode-pattern.svg);
  }

  .stitch {
      background-image: url(src/assets/mode-stitch.svg);
  }
}







.options-column-wrapper {
    overflow: hidden;
    background-color: white;
    box-shadow: 0px 0px 4px 2px #0000002b;
    z-index: 2;
    position: absolute;
    left: 130px;
    top: 5px;
    bottom: 5px;
    margin: 0 0 0 20px;
    border-radius: 5px;
    font-size: 0.8em;

    --column-template: 55px repeat(3, 50px) repeat(2, 40px) 45px;
    --column-width: 335px; // 295px; // 5 + (4*50) + (2*40) + 5 + 5 = 295
    .cell-headers {
        grid-template-columns: var(--column-template);
    }
    .options-column {
        grid-template-columns: var(--column-template);
    }
    &.with-direction {
        --column-template: 55px repeat(3, 50px) repeat(3, 40px) 45px;
        --column-width: 375px; // 335px; // 5 + (4*50) + (3*40) + 5 + 5 =
    }
    &.no-direction .options-column .cell-wrapper {
        &:nth-child(7n-6) {
            // padding-left: 5px;
            cursor: e-resize;
        }
        &:nth-child(7n) {
            padding-right: 5px;
        }
    }
    &.with-direction .options-column .cell-wrapper {
        &:nth-child(8n-7) {
            // padding-left: 5px;
            cursor: e-resize;
        }
        &:nth-child(8n) {
            padding-right: 5px;
        }
    }

    .cell-headers {
        pointer-events: none;
        position: absolute;
        background-color: white;
        display: grid;
        width: 100%;
        height: 35px;
        padding: 0;
        bottom: 15px;
        opacity: .9;
        div {
            display: flex;
            justify-content: center;
            align-self: center;
            padding: 0;
            text-align: center;
            &:first-child {
                background-color: #F4F6F8;
                margin-left: 5px;
                margin-right: 5px;
                width: calc(100% - 10px); // left + right margin
                height: 100%;
            }
            &:last-child {
              margin-right: 5px;
            }
        }
    }
    .options-column {
        display: grid;
        // grid-template-columns: 50px repeat(2, 40px) repeat(3, 32px);
        // grid-template-columns: 55px repeat(3, 50px) 40px 45px;
        position: relative;
        color: #3C3C3C;
        padding: 0;

        &:active,
        &:focus,
        &:focus-visible {
          outline: none; // prevent outline from focus
        }

        $minHeight: 20;
        @for $i from 1 to $minHeight {
          &.rh-#{$i} .cell-wrapper .cell {
            line-height: calc(5px + (#{$i}px - 5px) / 2);
          }
        }

        .cell-wrapper {
            width: 100%;
            height: 100%;
            display: flex;
            align-content: center;
            justify-content: center;
            cursor: cell;

            .cell {
                user-select: none;
                pointer-events: none;
                cursor: cell;
                margin: 1px 5px;
                padding: 2px;
                border-radius: 5px;
                line-height: 15px; /* XXX different from vertical ruler */
                background: var(--cell-color, white);
                // &:focus-visible {
                //     background: white;
                //     outline: 2px solid var(--cell-color, gray);
                // }

                display: flex;
                justify-content: center;
                align-self: center;
                text-align: center;
                border: 2px solid white; // pre-allocate border
                &.cell-index {
                    border: 0;
                }
            }
            &.selected .cell {
                background: white;
                border: 2px solid var(--cell-color, gray);
            }
            &.editing .cell {
                border: 2px solid gray;
            }
            &.editing-empty .cell {
                color: silver;
            }
            &.drag-edit .cell,
            &.paste-edit .cell {
                color: #573aff !important;
            }
            &.highlighted .cell-index,
            .cell-index.highlighted {
                // background: #EAEDF0;
                background: #E3E9EA;
            }
            .cell-index {
                // pointer-events: none;
                background: #F4F6F8;
                --cell-color: #F4F6F8;
                align-items: center;
                height: 100%;
                width: 100%;
                border-radius: 0;
            }
            .option-racking {
                //--cell-color: #FCC0DE;
                --cell-color:#EAEDF0;
                justify-content: end;
                padding-right: 7px;
                min-width: 40px;
            }
            .option-speed {
                //--cell-color: white;
                --cell-color:#EAEDF0;
                min-width: 40px;
            }
            .option-roll {
                //--cell-color: #AFD9FF;
                --cell-color:#EAEDF0;
                min-width: 40px;
            }
            .option-fs {
                //--cell-color: #FF8B82;
                --cell-color:#EAEDF0;
                min-width: 30px;
            }
            .option-rs {
                //--cell-color: #6C95DE;
                --cell-color:#EAEDF0;
                min-width: 30px;
            }
            .option-dir {
                --cell-color: #EAEDF0;
                min-width: 30px;
                position: relative;
                &.left::before {
                    display: block;
                    position: absolute;
                    content: '<';
                    left: 0;
                    color: gray;
                }
                &.right::after {
                    display: block;
                    position: absolute;
                    content: '>';
                    right: 0;
                    color: gray;
                }
                &.no-yarn {
                    color: silver;
                }
            }
            .option-carrier {
                --cell-color:#EAEDF0;
                &.y0 {
                    color: silver;
                }
                &.y1 {
                    --cell-color:rgb(226,115,0);
                }
                &.y2 {
                    --cell-color:rgb(115,216,255);
                }
                &.y3 {
                    --cell-color:rgb(252,220,0);
                }
                &.y4 {
                    --cell-color:rgb(253,161,255);
                }
                &.y5 {
                    --cell-color:rgb(0,98,177);
                }
                &.y6 {
                    --cell-color:rgb(104,188,0);
                }
                min-width: 30px;
            }
        }

        // --- row highlight
        .mouse-row {
            pointer-events: none;
            grid-column: 1 / -1;
            width: 100%;
            height: 100%;
            z-index: -3;
            background: #f7f7f7;
        }

        // pointers

        &.selecting-cells .cell-wrapper {
            cursor: cell;
        }
        &.selecting-rows .cell-wrapper {
            cursor: e-resize;
        }
        &.pasting .cell-wrapper,
        &.pasting .cell-wrapper:nth-child(6n-5),
        &.pasting .cell-wrapper:nth-child(7n-6) {
            cursor: pointer;
        }

        // --- start of grid-selection
        .selection-above,
        .selection-below,
        .selection-drag,
        .selection-paste-below,
        .selection-paste-above {
            pointer-events: none;
            // width: calc(100% - 4px);
            width: 100%;
            height: 100%;
            border-radius: 5px;
            // margin-left: 2px;
            // margin-right: 2px;

            &.empty {
                display: none;
            }
            &.to-last {
                width: calc(100% - 5px);
                margin-right: 5px;
            }
        }
        .selection-below {
            z-index: -1;
            background: #F4F6F8;
            border: 1px solid white;

            &.drag-overwrite {
                background: #e5fbe6;
            }
            transition: background-color 0.5s;
        }
        .selection-drag {
            &.drag-resize {
                z-index: 4;
                background: rgba(255, 255, 255, 0.5);
                border: 1px solid #b3b4b4;
            }
            &.drag-overwrite {
                z-index: -2;
                background: #ecf3ed; // #bdd7f1;
                border: 1px dashed #228b45;
            }
        }
        .selection-paste-below {
            z-index: -1;
            background: #d3e9ff;
            border: 1px solid #ad9efd;
        }
        .selection-paste-above {
            z-index: 3;
            background: transparent;
            border: 1px dotted #878a91;
        }
        .selection-above {
            z-index: 3;
            background: transparent;
            border: 1px dotted #878a91;

            &.drag-overwrite {
                border-top: 2px dashed #228b45; // #6694ff;
                border-bottom: 2px dashed #228b45; // #6694ff;

                .handle-wrapper {
                    .handle-up {
                        cursor: n-resize;
                    }
                    .handle-down {
                        cursor: s-resize;
                    }
                }
            }

            .handle-wrapper {
                position: relative;
                width: 100%;
                height: 100%;

                .handle-up,
                .handle-down {
                    display: none;
                    pointer-events: none;
                    cursor: row-resize;
                    position: absolute;
                    width: 100%;
                    height: 10px;

                    .handle {
                        display: none;
                        pointer-events: none;
                        position: absolute;
                        left: 50%;
                        margin-left: -10px;
                        width: 20px;
                        height: 10px;
                        border-radius: 3px;
                        background: white;
                        border: 1px solid gray;
                        box-shadow: 0 0 0 2px white; /* secondary border */
                    }
                }
                .handle-up {
                    top: -5px;

                    .handle {
                        border-bottom: 1px dotted silver;
                    }
                }
                .handle-down {
                    bottom: -5px;

                    .handle {
                        border-top: 1px dotted silver;
                    }
                }

                // only make handle region and handles visible and active
                // when the selection is ready
                &.ready {
                    .handle-up,
                    .handle-down {
                      display: block;
                      pointer-events: all;
                    }

                    .handle-up:hover .handle,
                    .handle-down:hover .handle {
                        display: block;
                        pointer-events: none;
                    }
                }
            }
        }
        // --- end of grid-selection
    }
    // input outside of grid
    .selection-input {
        // @see https://fuzzbomb.github.io/accessibility-demos/visually-hidden-focus-test.html
        position: fixed; // absolute / relative can mess up the layout sometimes? though likely bugs in Chrome!
        bottom: -200px;
        opacity: 0;

        // for debug:
        // &.ready {
        //     position: fixed;
        //     left: 100px;
        //     top: 100px;
        // }
  }
}

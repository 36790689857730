.settings-form {
  display: flex;
  flex-flow: column wrap;
  gap: 10px;
  padding: 10px;
  font-size: 12px;
  background: #FFFFFF;

  label { cursor: pointer; }
  .title {
    cursor: default;
    font-size: 16px;
    color: #79878F;
    text-align: center;
    gap: 5px;
    text-transform: capitalize;
    padding-bottom: 5px;
  }
  .section {
    cursor: default;
    align-self: start;
    font-size: 120%;
    color: #666;
    padding-left: 10px;
    border-left: 3px solid #666;
  }
  .setting {
    // display: flex;
    // flex-direction: row;
    // width: 100%;
    display: block;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    
    >label {
      cursor: pointer;
      display: flex;
      gap: 2px;
      margin: 0 5px;
    }
    input[type=checkbox] {
      accent-color: #79878F;
    }
    input, select {
      align-self: center;
      float: right;
      width: 50px;
    }
  }
}

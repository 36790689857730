.form {
  background: white;
  border-radius: 3px;
  display: flex;
  flex-flow: column wrap;
  gap: 10px;
  padding: 10px;
  font-size: 12px;
  width: 500px;
  .title {
    font-size: 16px;
    color: #79878F;
    text-align: center;
    gap: 5px;
    text-transform: capitalize;
    padding-bottom: 5px;
    user-select: none;
  }
  input {
    accent-color: #79878F;
  }
  .checkbox-input {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }
}
@mixin icons {
  .rear-knit-icon {
    background-image: url(src/assets/stitch-selector-icons/basic/rear-knit.svg);
    &:hover,&.active {
      box-shadow: 0px 0px 0px 2px white, 0px 0px 0px 3px #FFE397;
    }
  }
  .rear-tuck-icon {
    background-image: url(src/assets/stitch-selector-icons/basic/rear-tuck.svg);
    &:hover,&.active {
      box-shadow: 0px 0px 0px 2px white, 0px 0px 0px 3px #FF8A83;
    }
  }
  .rear-drop-icon {
    background-image: url(src/assets/stitch-selector-icons/basic/rear-drop.svg);
    &:hover,&.active {
      box-shadow: 0px 0px 0px 2px white, 0px 0px 0px 3px #F0E4E1;
    }
  }
  .rear-split-icon {
    background-image: url(src/assets/stitch-selector-icons/basic/rear-split.svg);
    &:hover,&.active {
      box-shadow: 0px 0px 0px 2px white, 0px 0px 0px 3px #CD6F64;
    }
  }

  .front-knit-icon {
    background-image: url(src/assets/stitch-selector-icons/basic/front-knit.svg);
    &:hover,&.active {
      box-shadow: 0px 0px 0px 2px white, 0px 0px 0px 3px #AFD9FF;
    }
  }

  .front-tuck-icon {
    background-image: url(src/assets/stitch-selector-icons/basic/front-tuck.svg);
    &:hover,&.active {
      box-shadow: 0px 0px 0px 2px white, 0px 0px 0px 3px #6B93DB;
    }
  }
  .front-drop-icon {
    background-image: url(src/assets/stitch-selector-icons/basic/front-drop.svg);
    &:hover,&.active {
      box-shadow: 0px 0px 0px 2px white, 0px 0px 0px 3px #C3A6A0;
    }
  }

  .front-split-icon {
    background-image: url(src/assets/stitch-selector-icons/basic/front-split.svg);
    &:hover,&.active {
      box-shadow: 0px 0px 0px 2px white, 0px 0px 0px 3px #3675E3;
    }
  }

  .knit-both-icon {
    background-image: url(src/assets/stitch-selector-icons/basic/knit-both.svg);
    width: 60px;
    &:hover, &.active {
      top: -3px;
      height: 36px;
      background-image: url(src/assets/stitch-selector-icons/basic/knit-both-outline.svg);
    }
  }
  .tuck-knit-icon {
    background-image: url(src/assets/stitch-selector-icons/basic/tuck-knit.svg);
    width: 60px;
    &:hover, &.active {
      top: -3px;
      height: 36px;
      background-image: url(src/assets/stitch-selector-icons/basic/tuck-knit-outline.svg);
    }
  }
  .tuck-both-icon {
    background-image: url(src/assets/stitch-selector-icons/basic/tuck-both.svg);
    width: 60px;
    &:hover, &.active {
      top: -3px;
      height: 36px;
      background-image: url(src/assets/stitch-selector-icons/basic/tuck-both-outline.svg);
    }
  }
  .knit-tuck-icon {
    background-image: url(src/assets/stitch-selector-icons/basic/knit-tuck.svg);
    width: 60px;
    &:hover, &.active {
      top: -3px;
      height: 36px;
      background-image: url(src/assets/stitch-selector-icons/basic/knit-tuck-outline.svg);
    }
  }
  .drop-both-icon {
    background-image: url(src/assets/stitch-selector-icons/basic/drop-both.svg);
    width: 60px;
    &:hover,&.active {
      height: 36px;
      background-image: url(src/assets/stitch-selector-icons/basic/drop-both-outline.svg);
    }
  }
  .clear-icon {
    // background-image: url(src/assets/stitch-selector-icons/basic/drop-both.svg);
    background-image: url(src/assets/stitch-selector-icons/basic/clear.svg);
    width: 30px;
    &:hover,&.active {
      box-shadow: 0px 0px 0px 2px white, 0px 0px 0px 3px #FFE0FF;
    }
  }
  .miss-icon {
    // background-image: url(src/assets/stitch-selector-icons/basic/drop-both.svg);
    background-image: url(src/assets/stitch-selector-icons/basic/miss.svg);
    width: 30px;
    &:hover,&.active {
      box-shadow: 0px 0px 0px 2px white, 0px 0px 0px 3px #AFF1C3;
    }
  }

  .rl4-icon {
    background-image: url(src/assets/stitch-selector-icons/transfer/rl4.svg);
    &:hover,&.active {
      box-shadow: 0px 0px 0px 2px white, 0px 0px 0px 3px #000;
    }
  }
  .rl3-icon {
    background-image: url(src/assets/stitch-selector-icons/transfer/rl3.svg);
    &:hover,&.active {
      box-shadow: 0px 0px 0px 2px white, 0px 0px 0px 3px #555;
    }
  }
  .rl2-icon {
    background-image: url(src/assets/stitch-selector-icons/transfer/rl2.svg);
    &:hover,&.active {
      box-shadow: 0px 0px 0px 2px white, 0px 0px 0px 3px #999;
    }
  }
  .rl1-icon {
    background-image: url(src/assets/stitch-selector-icons/transfer/rl1.svg);
    &:hover,&.active {
      box-shadow: 0px 0px 0px 2px white, 0px 0px 0px 3px #EEE;
    }
  }

  .fl4-icon {
    background-image: url(src/assets/stitch-selector-icons/transfer/fl4.svg);
    &:hover,&.active {
      box-shadow: 0px 0px 0px 2px white, 0px 0px 0px 3px #000;
    }
  }
  .fl3-icon {
    background-image: url(src/assets/stitch-selector-icons/transfer/fl3.svg);
    &:hover,&.active {
      box-shadow: 0px 0px 0px 2px white, 0px 0px 0px 3px #555;
    }
  }
  .fl2-icon {
    background-image: url(src/assets/stitch-selector-icons/transfer/fl2.svg);
    &:hover,&.active {
      box-shadow: 0px 0px 0px 2px white, 0px 0px 0px 3px #999;
    }
  }
  .fl1-icon {
    background-image: url(src/assets/stitch-selector-icons/transfer/fl1.svg);
    &:hover,&.active {
      box-shadow: 0px 0px 0px 2px white, 0px 0px 0px 3px #EEE;
    }
  }

  .r0-icon {
    background-image: url(src/assets/stitch-selector-icons/transfer/r0.svg);
    &:hover,&.active {
      box-shadow: 0px 0px 0px 2px white, 0px 0px 0px 3px #999;
    }
  }
  .f0-icon {
    background-image: url(src/assets/stitch-selector-icons/transfer/f0.svg);
    &:hover,&.active {
      box-shadow: 0px 0px 0px 2px white, 0px 0px 0px 3px #999;
    }
  }

  .rr1-icon {
    background-image: url(src/assets/stitch-selector-icons/transfer/rr1.svg);
    &:hover,&.active {
      box-shadow: 0px 0px 0px 2px white, 0px 0px 0px 3px #EEE;
    }
  }
  .rr2-icon {
    background-image: url(src/assets/stitch-selector-icons/transfer/rr2.svg);
    &:hover,&.active {
      box-shadow: 0px 0px 0px 2px white, 0px 0px 0px 3px #999;
    }
  }
  .rr3-icon {
    background-image: url(src/assets/stitch-selector-icons/transfer/rr3.svg);
    &:hover,&.active {
      box-shadow: 0px 0px 0px 2px white, 0px 0px 0px 3px #555;
    }
  }
  .rr4-icon {
    background-image: url(src/assets/stitch-selector-icons/transfer/rr4.svg);
    &:hover,&.active {
      box-shadow: 0px 0px 0px 2px white, 0px 0px 0px 3px #000;
    }
  }

  .fr1-icon {
    background-image: url(src/assets/stitch-selector-icons/transfer/fr1.svg);
    &:hover,&.active {
      box-shadow: 0px 0px 0px 2px white, 0px 0px 0px 3px #EEE;
    }
  }
  .fr2-icon {
    background-image: url(src/assets/stitch-selector-icons/transfer/fr2.svg);
    &:hover,&.active {
      box-shadow: 0px 0px 0px 2px white, 0px 0px 0px 3px #999;
    }
  }
  .fr3-icon {
    background-image: url(src/assets/stitch-selector-icons/transfer/fr3.svg);
    &:hover,&.active {
      box-shadow: 0px 0px 0px 2px white, 0px 0px 0px 3px #555;
    }
  }
  .fr4-icon {
    background-image: url(src/assets/stitch-selector-icons/transfer/fr4.svg);
    &:hover,&.active {
      box-shadow: 0px 0px 0px 2px white, 0px 0px 0px 3px #000;
    }
  }

  .rfl-4-icon {
    background-image: url(src/assets/stitch-selector-icons/knit-transfer/rfl-4.svg);
    &:hover,.active {
      box-shadow: 0px 0px 0px 2px white, 0px 0px 0px 3px #000;
    }
  }
  .rfl-3-icon {
    background-image: url(src/assets/stitch-selector-icons/knit-transfer/rfl-3.svg);
    &:hover,.active {
      box-shadow: 0px 0px 0px 2px white, 0px 0px 0px 3px #555;
    }
  }
  .rfl-2-icon {
    background-image: url(src/assets/stitch-selector-icons/knit-transfer/rfl-2.svg);
    &:hover,.active {
      box-shadow: 0px 0px 0px 2px white, 0px 0px 0px 3px #999;
    }
  }
  .rfl-1-icon {
    background-image: url(src/assets/stitch-selector-icons/knit-transfer/rfl-1.svg);
    &:hover,.active {
      box-shadow: 0px 0px 0px 2px white, 0px 0px 0px 3px #ddd;
    }
  }

  .frl-4-icon {
    background-image: url(src/assets/stitch-selector-icons/knit-transfer/frl-4.svg);
    &:hover,.active {
      box-shadow: 0px 0px 0px 2px white, 0px 0px 0px 3px #000;
    }
  }
  .frl-3-icon {
    background-image: url(src/assets/stitch-selector-icons/knit-transfer/frl-3.svg);
    &:hover,.active {
      box-shadow: 0px 0px 0px 2px white, 0px 0px 0px 3px #555;
    }
  }
  .frl-2-icon {
    background-image: url(src/assets/stitch-selector-icons/knit-transfer/frl-2.svg);
    &:hover,.active {
      box-shadow: 0px 0px 0px 2px white, 0px 0px 0px 3px #999;
    }
  }
  .frl-1-icon {
    background-image: url(src/assets/stitch-selector-icons/knit-transfer/frl-1.svg);
    &:hover,.active {
      box-shadow: 0px 0px 0px 2px white, 0px 0px 0px 3px #ddd;
    }
  }

  .down-icon {
    background-image: url(src/assets/stitch-selector-icons/knit-transfer/down.svg);
    &:hover,.active {
      box-shadow: 0px 0px 0px 2px white, 0px 0px 0px 3px #999;
    }
  }
  .up-icon {
    background-image: url(src/assets/stitch-selector-icons/knit-transfer/up.svg);
    &:hover,.active {
      box-shadow: 0px 0px 0px 2px white, 0px 0px 0px 3px #999;
    }
  }

  .rfr-1-icon {
    background-image: url(src/assets/stitch-selector-icons/knit-transfer/rfr-1.svg);
    &:hover,.active {
      box-shadow: 0px 0px 0px 2px white, 0px 0px 0px 3px #ddd;
    }
  }

  .rfr-2-icon {
    background-image: url(src/assets/stitch-selector-icons/knit-transfer/rfr-2.svg);
    &:hover,.active {
      box-shadow: 0px 0px 0px 2px white, 0px 0px 0px 3px #999;
    }
  }

  .rfr-3-icon {
    background-image: url(src/assets/stitch-selector-icons/knit-transfer/rfr-3.svg);
    &:hover,.active {
      box-shadow: 0px 0px 0px 2px white, 0px 0px 0px 3px #555;
    }
  }
  .rfr-4-icon {
    background-image: url(src/assets/stitch-selector-icons/knit-transfer/rfr-4.svg);
    &:hover,.active {
      box-shadow: 0px 0px 0px 2px white, 0px 0px 0px 3px #000;
    }
  }

  .frr-1-icon {
    background-image: url(src/assets/stitch-selector-icons/knit-transfer/frr-1.svg);
    &:hover,.active {
      box-shadow: 0px 0px 0px 2px white, 0px 0px 0px 3px #ddd;
    }
  }

  .frr-2-icon {
    background-image: url(src/assets/stitch-selector-icons/knit-transfer/frr-2.svg);
    &:hover,.active {
      box-shadow: 0px 0px 0px 2px white, 0px 0px 0px 3px #999;
    }
  }

  .frr-3-icon {
    background-image: url(src/assets/stitch-selector-icons/knit-transfer/frr-3.svg);
    &:hover,.active {
      box-shadow: 0px 0px 0px 2px white, 0px 0px 0px 3px #555;
    }
  }
  .frr-4-icon {
    background-image: url(src/assets/stitch-selector-icons/knit-transfer/frr-4.svg);
    &:hover,.active {
      box-shadow: 0px 0px 0px 2px white, 0px 0px 0px 3px #000;
    }
  }
}

.layerspanel {
  margin: 0;
  padding: 0;
  width: 280px;
  background: repeating-linear-gradient(
    0deg,
    #F8F9FA,
    #F8F9FA 45px,
    #fff 0px,
    #fff 90px
  );
  li {

    list-style: none;
    height: 45px;
    position: relative;
    .layer {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 45px;
      gap: 5px;
      color: black;

      &:hover:before {
        content: "";
        border: 1px solid #97A7B5;
        position: absolute;
        top: 0;
        height: 43px;
        width: 278px;
        right: 0;
        border-radius: 5px;
        pointer-events: none;
      }

      .controls {
        margin: 0 15px 0 auto;
        >img { cursor: pointer; }
      }

      img.layer-preview {
        padding: 3px;
        border-radius: 3px;
        background: white;
        height: 30px;
        width: 30px;
        vertical-align: middle;
      }
    }

    &.container-layer {
      position: relative;

      .layer {
        padding-left: 0;
        >.arrow {
          width: 12px;
          height: 12px;
          cursor: pointer;
          background: url(src/assets/layers-arrow-closed.svg) no-repeat center;
          background-size: cover;
          transition: .25s transform ease;
        }
      }

      >ul { display: none; }

      &.open {
        height: auto;
        >.layer>.arrow { transform: rotate(90deg); }
        >ul { display: block; }
      }
    }

    ul {
      padding: 0;
      margin: 0;
    }
  }
}

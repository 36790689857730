.viewer {
  // background-color: #FFDF8C;
  // background-image:
  //   repeating-linear-gradient(45deg, #A6D4FF 25%, transparent 25%, transparent 75%, #A6D4FF 75%, #A6D4FF),
  //   repeating-linear-gradient(45deg, #A6D4FF 25%, #FFDF8C 25%, #FFDF8C 75%, #A6D4FF 75%, #A6D4FF);
  // background-position: 0 0, 23px 23px;
  // background-size: 46px 46px;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  // z-index: -1;
  canvas {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.contextmenu {
  background: white;
  border-radius: 5px;
  position: absolute;
  padding: 10px;
  width: 280px;

  &:before {
    content: '';
    border-width: 10px;
    border-style: solid;
    border-color: transparent;
    position: absolute;
    pointer-events: none;
  }

  &.rightArrow {
    transform:translate(-100%, -50%);
  }
  &.leftArrow {
    transform:translate(0, -50%);
  }
  &.topArrow {
    transform:translate(-50%, 0);
  }
  &.bottomArrow {
    transform:translate(-50%, -100%);
  }
  &.topArrow:before {
    border-bottom-color: white;
    top: -20px;
    left: calc(50% - 5px);
  }

  &.bottomArrow:before {
    border-top-color: white;
    bottom: -20px;
    left: calc(50% - 5px);
  }

  &.leftArrow:before {
    border-right-color: white;
    top: calc(50% - 5px);
    left: -20px;
  }

  &.rightArrow:before {
    border-left-color: white;
    top: calc(50% - 5px);
    right: -20px;
  }
}

.editor {
  .ribbon, .sidebar, .options-column-wrapper, .vertical-ruler-wrapper, .horizontal-ruler-wrapper {
    opacity: 1;
    transition: opacity 50ms ease 0s;
  }

  &.editing {
    *,
    .ribbon .ribbon-content .item {
      pointer-events: none;
    }
    > .viewer canvas {
      pointer-events: all;
    }

    .ribbon,
    .sidebar,
    .options-column-wrapper,
    .vertical-ruler-wrapper,
    .horizontal-ruler-wrapper,
    .view-control {
      opacity: 0.5;
      transition: opacity 100ms ease 250ms;
    }
  }
}

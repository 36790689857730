.scriptbox {
  width: 100%;

  .script-panel {
    position: relative;
    .help {
      position: absolute;
      right: 5px;
      top: 5px;
      z-index: 2;
      width: 10px;
      height: 10px;
      &::before {
        position: absolute;
        right: 0;
        top: 0;
        width: 20px;
        height: 20px;
        border-radius: 10px;
        content: '?';
        line-height: 20px;
        background: #EFEFEF;
        cursor: help;
        text-align: center;
      }
    }
    .documentation {
      display: none;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      overflow: auto;
      z-index: 1;
      background: rgba(255, 255, 255, 0.8);
      padding: 20px;
      font-size: .8em;
      user-select: text;

      h2 {
        font-size: 1.2em;
      }

      ul.args {
        .name {
          font-family: monospace;
          font-weight: bold;
        }
        .type {
          font-family: monospace;
          color: silver;
          &::before {
            content: '(';
          }
          &::after {
            content: ')';
          }
        }
      }

      ul.compact {
        list-style: none;
        padding-left: 15px;
        font-family: monospace;

        li {
          display: inline-block;
          margin: 2px;
          padding: 2px;
          border: 1px dotted #F0F0F0;
          background: #F9F9F9;

          &.func::before {
            content: 'fun ';
            color: red;
          }
          &.func.args-0::after {
            content: '()'
          }
          &.func.args-1::after {
            content: '(x)'
          }
          &.func.args-2::after {
            content: '(x,y)'
          }
          &.func.args-3::after {
            content: '(x,y,z)'
          }

          &.prop::before {
            content: 'prop ';
            color: blue;
          }
        }
      }

    }
    .documentation.visible {
      display: block;
    }
    .cm-editor {
      cursor: text;
    }
    .script-log {
      min-height: 20px;
      margin-top: 10px;
      background: white;

      &::before {
        content: 'No error';
        color: silver;
      }
      &.error {
        color: red;
      }
      &.error::before,
      &.result::before {
        content: ''
      }
    }
    .script-actions {
      display: flex;
      background: white;

      a, button {
        border: 1px solid #79878F;
        color: #79878F;
        border-radius: 3px;
        background-color: #EFEFEF;
        cursor: pointer;
        margin: 10px;

        &:hover {
          border-color: black;
        }
      }
      a {
        padding: 1px 6px;
        &:hover, &:active, &:focus, &:visited {
          text-decoration: none;
        }
      }
      button:last-child {
        margin-left: auto;
      }
    }
  }

}

.checksbox {
  width: 100%;
  min-height: 140px;
  background: white;

  .check-panel {
    position: relative;
    display: flex;
    flex-direction: column;

    > .count {
      font-size: 1em;
      text-align: center;
      background: #efefef;
      line-height: 1em;

      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 5px;
      
      span {
        border-width: 3px 3px 0 3px;
        border-style: solid;
        border-color: #efefef;
        cursor: pointer;
      }

      &.issues .issues,
      &.kicks .kicks,
      &.moves .moves {
        background: white;
      }
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 5px 0;
      overflow-y: scroll;
      max-height: 400px;

      &.empty::after {
        content: 'None found.';
        display: block;
        margin: 20px;
        color: rgb(0, 178, 113);
      }

      li.block {
          margin: 10px 20px;
          padding: 2px 10px;
          font-style: italic;
          cursor: pointer;
          color: silver;

          &:hover {
            color: gray;
            background: #fafaff;
          }

          &::before, &::after {
            content: ' ... ';
          }
      }

      li.issue {
        padding-left: 7px;
        background: #DFDFDF;
        margin-bottom: 5px;
        position: relative;
        cursor: pointer;

        border-left: 2px solid white;
        &.selected {
          border-left: 2px solid gray;
        }

        .context {
          font-family: monospace;
          font-weight: bold;
          color: black;
          padding-right: 5px;

          &::before {
            content: '(';
          }
          &::after {
            content: ')';
          }
        }
        .message {
          display: block;
          padding: 5px 5px 10px 5px;
        }
        .source, .related {
          position: absolute;
          right: 0px;
          cursor: pointer;
          font-size: smaller;
        }
        .source {
          top: 0px;
        }
        .related {
          bottom: 0px;
        }
        &.verbose {
          background: #f3fcff;
          .context {
            color: #56c9ff;
            &::before {
              content: '(V/';
            }
          }
        }
        &.info {
          background: #f5f5f5;
          .context {
            color: #b8b8b8;
            &::before {
              content: '(N/';
            }
          }
        }
        &.warning {
          background: #fbf0dd;
          .context {
            color: #ffa200;
            &::before {
              content: '(W/';
            }
          }
        }
        &.danger {
          background: rgb(255, 230, 238);
          .context {
            color: rgb(255, 0, 81);
            &::before {
              content: '(D/';
            }
          }
        }
        &.invalid {
          background: #ffdede;
          .context {
            color: black;
            &::before {
              content: '(I/';
            }
          }
        }

        // sections
        &.header {
          margin-top: 1.5em;
          &::before {
            display: block;
            position: absolute;
            top: -1.5em;
            font-weight: bold;
          }
          &.stage-knitout::before {
            content: '- knitout -';
          }
          &.stage-simulation::before {
            content: '- simulation -';
          }
          &.stage-kcode::before {
            content: '- kcode -';
          }
        }
      }
    }

    .check-actions {
      display: flex;
      background: white;

      label {
        margin: 10px;
        align-self: center;
        // background: #DFDFDF;
        border: 1px solid #79878F;
        background-color: #EFEFEF;
        border-radius: 3px;
        cursor: pointer;
        padding: 5px 0px 5px 15px;

        input {
          margin: 0 15px;
        }
      }

      a, button {
        border: 1px solid #79878F;
        color: #79878F;
        border-radius: 3px;
        background-color: #EFEFEF;
        cursor: pointer;
        margin: 10px;

        &:hover {
          border-color: black;
        }
      }
      a {
        padding: 1px 6px;
        &:hover, &:active, &:focus, &:visited {
          text-decoration: none;
        }
      }
      label:first-child {
        margin-left: auto;
      }
    }
  }

}

.designs {
    display: grid;
    grid-template-columns: repeat(auto-fit, 350px);
    justify-content: center;
    padding: 45px;
    gap: 45px;
    user-select: none;

    .design {
      display: grid;
      grid-template-columns:1fr 150px;
      grid-template-rows: 150px 1fr 1fr;
      height: 230px;
      width: 350px;
      border-radius: 3px;
      background: #FFFFFF;
      padding: 5px;

      box-shadow: 0 0 10px #d3d3d36e;
      border: 1px solid transparent;

      &.hidden {
        display: none;
      }

      &:hover {
        box-shadow: 0 0 10px #9b9b9b6e;
        border: 1px solid #A1B0BD;
      }

      .preview {
        grid-column-start: 1;
        grid-column-end: span 2;
        grid-row-start: 1;
        width: 340px;
        height: 150px;
        background: #F4F6F8;
        user-select: initial;
        img {
          height: 100%;
          width: 100%;
        }
      }

      .name {

        grid-column-start: 1;
        grid-row-start: 2;
        align-self: end;
        padding: 10px 0 0 10px;
        font-size: 18px;

        a {
          text-decoration: none;
          color: black;
        }
      }



      &.new {
        cursor: pointer;
        .preview {
          background: #F4F6F8;
          display: flex;
          align-items: center;
          justify-items: center;

          &:after {
            content: '+';
            font-size: 4em;
            color: #D3DAE0;
            width: 100%;
            text-align: center;
          }
        }
        .name {
          align-self: center;
            grid-row-end: span 2;
        }
      }

      .lastUpdated {
        grid-column-start: 1;
        grid-row-start: 3;
        padding: 0 0 0 10px;
        font-size: 14px;
        color: #A1B0BD;
      }

      .action-icons {
        align-self: center;
        justify-self: end;
        grid-column-start: 2;
        grid-row-start: 2;
        grid-row-end: span 2;
        display: flex;
        .icon {
            cursor: pointer ;
            background-repeat: no-repeat;
            height: 25px;
            width: 25px;
            margin: 10px;
            background-position: center;
        }
        .colab-icon { background-image: url(src/assets/designs-collaborators-icon.svg); }
        .share-icon { background-image: url(src/assets/designs-share-icon.svg); }
        .more-icon { background-image: url(src/assets/designs-more-icon.svg); }
        .more-menu {
          position: relative;
          .menu {
            position: absolute;
            background: white;
            box-shadow: 0 0 10px #9a99996e;
            left: calc(-50px + (35px / 2) - 5px);
            top: 45px;
            width: 100px;
            border-radius: 5px;
            padding: 5px 5px 5px 30px;
            &:before {
              content: '';
              border-width: 10px;
              border-style: solid;
              border-color: transparent transparent #e6e6e6 transparent;
              position: absolute;
              top: -20px;
              left: calc(50% - 10px);
              pointer-events: none;
            }
            &.upper {
              top: -115px;
              &:before {
                content: '';
                border-color: #e6e6e6 transparent transparent transparent;
                top: 100px;
              }
            }
            div {
              position: relative;
              cursor: pointer;
              color: #74838B;
              padding: 5px 0;
              height: 30px;
              &:hover { text-decoration: underline; }
              &:before {
                content: '';
                width: 20px;
                height: 20px;
                position: absolute;
                left: -25px;
                background-repeat: no-repeat;
                background-size: 20px 20px;
              }
            }
            .rename-action:before { background-image: url(src/assets/rename-icon.svg); }
            .copy-action:before { background-image: url(src/assets/copy-icon.svg); }
            .delete-action:before { background-image: url(src/assets/delete-icon.svg); }
          }
        }
      }
    }
  }

.image-upload {
    .form {
        background: white;
        border-radius: 3px;
        display: flex;
        flex-flow: column wrap;
        gap: 10px;
        padding: 10px;
        font-size: 12px;
        width: 500px;
        input {
            accent-color: #79878F;
        }

        .title {
            font-size: 16px;
            color: #79878F;
            text-align: center;
            gap: 5px;
            text-transform: capitalize;
            padding-bottom: 5px;
            user-select: none;
        }

        .image-preview {
            max-height: 400px;
            overflow: auto;
            align-self: center;
            text-align: center;
            text-transform: capitalize;
            img {
                width: 465px;
                image-rendering: pixelated;
            }
        }

        .button-group {
            display: flex;
            flex-direction: row;
            justify-content: right;
            gap: 10px;
        }

        .radio-group {
            display: flex;
            flex-direction: column;
            gap: 5px;

            label {
                cursor: pointer;
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 2px;
            }
            .title {
                cursor: initial;
                font-size: 14px;
            }
        }

        .input-group {
            display: flex;
            flex-direction: column;
            gap: 5px;

            .title {
                margin-top: 10px;
                font-size: 14px;
                text-align: left;
            }
        }

        .checkbox-input {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 5px;
            label { cursor: pointer; }
        }

        .range-group {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            flex-grow: 1;
            >label {
                min-width: 85px;
                text-transform: capitalize;
                cursor: pointer;
                display: flex;
                gap: 2px;
            }
            input {
                cursor: grab;
                flex-grow: 2;
                &:active { cursor: grabbing; }
            }
        }

        .inline-group {
            gap: 10px;
            display: flex;
            flex-direction: row;
            align-items: center;
        }
    }
}

.ribbon .action-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  .action-item > button {
    outline: none;
    border: none;
    margin: 0;
    padding: 0;
    background: transparent;
    width: 100%;
    height: 40px;
    transition: background-color .3s ease-in;
    &:hover {
      background-color: lightgray;

    }
  }
}

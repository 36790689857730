.sidebar {
  position: relative;
  margin: 50px 0 0 10px;
  width: 120px;
  z-index: 3;
}

.yarnbox, .stitchbox {
  display: flex;
  flex-flow: row wrap;
  margin-top: 10px;
  justify-content: center;
  user-select: none;
  padding: 15px 15px;
  border-radius: 5px;
  box-shadow: 1px 1px 5px 1px #0000002b;
  background-color: white;
  color: #74838C;

  .title {
      flex: 1 0 90px;
      position: relative;
      color: #74838C;
      font-size: 14px;
      cursor: pointer;
      padding-left: 5px;
      // &:before {
      //   content: '';
      //   border-width: .35rem;
      //   border-style: solid;
      //   border-color: transparent transparent transparent #A1B0BD;
      //   position: absolute;
      //   top: 3px;
      //   left: 0;
      //   transform: rotate(0);
      //   transform-origin: .2rem 50%;
      //   transition: .25s transform ease
      // }
  }

  &.open .title:before {
    transform: rotate(90deg);
  }
}

.yarnbox {
  height: 45px;
  transition: .25s height ease;
  gap: 12px;

  &.open {
    height: 200px;
    .yarnselector { visibility: visible; }
  }

  .yarnselector {
    visibility: hidden;
    flex: 1 0 37px;
    height: 37px;
    border: 3px white solid;
    background: transparent no-repeat center;
    position: relative;
    transition: box-shadow 0.2s ease;
    border-radius: 8px;
    // cursor: pointer;
    color: white;
    text-align: center;
    line-height: 33px;
    font-weight: 500;
    pointer-events: none;
    // &:hover, &.selected {
    //   box-shadow: 0 0 0px 1px #97a7b580;
    // }

    // &:nth-child(2) { background-color: #3157BF; }
    // &:nth-child(3) { background-color: #FFC135; }
    // &:nth-child(4) { background-color: #31B9BC; }
    // &:nth-child(5) { background-color: #8FE6EF; }
    // &:nth-child(6) { background-color: #7D67CE; }
    // &:nth-child(7) { background-color: #C1BDF4; }
  }
}


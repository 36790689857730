.loading {
  display: flex;
  gap: 5%;
  height: 200px;
  margin: 200px auto;
  width:100%;
  max-width: 500px;
  justify-content: center;
  align-items: center;
  background: url(src/assets/loading.svg) no-repeat center;
  // i {
  //   flex: 1;
  //   border-radius: 3px;
  //   box-shadow: 0 0 20px 1px #21212120;
  //   background: #212121;
  //   animation: loading 1.5s ease infinite;
  // }

  // @for $i from 1 through 5 {
  //   i:nth-child(#{$i}n) {
  //       animation-delay: #{$i * 0.1}s;
  //   }
  // }
}


// @keyframes loading {
//   0% {
//     height: 10%;
//   }
//   50%{
//     height: 100%;
//   }
//   100% {
//     height: 10%;
//   }
// }


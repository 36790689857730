.vertical-ruler-wrapper {
  overflow: hidden;
  background-color: white;
  box-shadow: 0px 0px 4px 2px #0000002b;
  z-index: 2;
  position: absolute;
  left: 135px;
  top: 5px;
  bottom: 5px;
  margin: 0 0 0 20px;
  border-radius: 5px;
  font-size: 0.8em;

  .vertical-ruler {
      display: grid;
      grid-template-columns: 1fr;
      position: relative;
      color: #3C3C3C;
      .vertical-ruler-row {
          display: flex;
          width: 100%;
          .cell {
              margin: 1px 0;
              padding: 2px;
              line-height: 15px;
              border-radius: 5px;
              align-self: center;
              text-align: center;
          }
          .cell-index {
              background: #F4F6F8;
              text-align: center;
              justify-content: center;
              display: flex;
              align-items: center;
              height: 100%;
              width: 45px;
              border-radius: 0;
          }
          &.highlighted .cell-index {
              background: #EAEDF0;
          }
      }
      .cell:first-child {
          background: #F4F6F8;
      }
  }
}

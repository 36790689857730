.user {
  opacity: 1;
  background: #2A9658;
  color: white;
  font-weight: 500;
  height: 65px;
  margin: 0;
  border-bottom: 1px solid white;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 10px;

  &:active {
    margin-top: 0;
  }
}

.avatar {
  border-radius: 50%;
  width: 35px;
  overflow: hidden;
  height: 35px;
  margin: 0 10px;
  border: 1px solid white;
  img { width: 35px; }
}

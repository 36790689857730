header {
    z-index: 10;
    grid-row-start:1;
    background: #252525;
    border-bottom: 1px white solid;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    box-shadow: 0px 1px 8px 0px #444;
    font-size: 16px;

    .logo {
      margin:0 20px;

      img {
        min-width: 63px;
      }
    }

    .filter-group {
      display: flex;
      gap: 10px;
      .filter {
        background: #333;
        border: 1px solid #444;
        padding: 5px 10px;
        color: gray;
        width: 300px;
        display: flex;
        align-items: center;
        gap: 10px;
        font-family: "Inter", sans-serif;

        &.nonempty::before {
          content: "Filter: ";
          display: inline-block;
          margin-left: 2px;
        }

        input[type=text] {
          outline: none;
          color: white;
          background: transparent;
          border: 0;
          display: inline;
          font-size: 1em;
        }

        width:  300px;
      }

      .filter-type,
      .sort-type {
        appearance: none;
        padding: 5px 10px;
        background: #333;
        color: gray;
        border: 1px solid #444;
        border-radius: 10px;
        cursor: pointer;
      }

    }

    button {
      cursor:pointer;
      padding: 0;
      flex-basis: 30px;
      height: 30px;
      background: no-repeat left bottom;
      border: none;
      opacity: .5;
      background-size: 30px;
      margin: 0 5px;
      font-size: 16px;
      transition: margin-top .2s ease;


      &:hover {
        opacity: 1;
      }

      &:focus-visible {
        outline: #5F6982 auto 1px;
      }

      &:active {
        margin-top: 5px;
      }
    }

    .undo {
      background-image:url(src/assets/nav-icon-undo.svg);
    }
    .redo {
      background-image:url(src/assets/nav-icon-redo.svg);
    }
    .save {
      background-image:url(src/assets/nav-icon-save.svg);
    }

    .copy {
      background-image:url(src/assets/nav-icon-copy.svg);
    }

    .undo, .redo {
      &:disabled {
        cursor:initial;
        opacity: .3;
      }
    }

    .designs-button {
      background-image:url(src/assets/nav-icons-designs.svg);
    }

    .design-name {
      background: black;
      color: white;
      border: none;
      border-radius: 5px;
      outline: none;
      height: 40px;
      width: 250px;
      font-weight: 500;
      font-size: 18px;
      padding: 0 10px;
      position: relative;
    }

    .share {
      background-image: url(src/assets/nav-icons-share.svg);
      color: #97A7B5;
      font-weight: 500;
      padding-left: 35px;
    }

    .knit {
      background-image: url(src/assets/nav-icons-knit.svg);
      color: #97A7B5;
      font-weight: 500;
      padding-left: 35px;
    }
  }

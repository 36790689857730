.view-control {
  position: absolute;
  left: calc(50% - 65px);
  bottom: 75px;
  background: white;
  height: 40px;
  width: 130px;
  border-radius: 3em;
  display: flex;
  justify-content: space-around;
  padding: 0 5px;
  box-shadow: 1px 1px 5px 1px #0000002b;
  z-index: 1;

  .item {
    flex: 1 0 20px;
    background: no-repeat center;
    cursor: pointer;
    border-radius: 5px;
    padding: 5px;
    margin: 5px;
    transition: box-shadow 0.2s ease;
    box-shadow: 0 0 0px 1px white;
    position: relative;

    &:hover {
      box-shadow: 0 0 0px 1px #97a7b580;
      &:before { border:1px solid #97a7b550; }
    }

    &:active {
      box-shadow: 0 0 0px 1px #97a7b550;
      &:before { border:1px solid #97a7b580; }
    }

    &.zoom-in {  background-image: url(src/assets/view-zoom-in.svg); }
    &.zoom-out { background-image: url(src/assets/view-zoom-out.svg); }
    &.zoom-center { background-image: url(src/assets/view-center.svg); }

    &:before {
      content: "";
      position: absolute;
      top: 2px;
      left: 2px;
      bottom: 2px;
      right: 2px;
      border-radius: 5px;
      border:1px solid transparent;
      transition: border-color 0.2s ease-in-out;
    }
  }
}


@import "~normalize.css/normalize.css";

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

input, button {
  font-family: 'Inter', sans-serif;
}

button { cursor: pointer; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#portal-root {
  position: absolute;
  z-index: 100;

  .dialog {
    position: relative;
    padding: 0;
    display: flex;
    flex-flow: column;
    border-radius: 3px;
    .close {
      position: absolute;
      top: 2px;
      right: 0;
      background: transparent;
      border: none;
      &:focus { outline: none; }
    }

    ul {
      margin-block: .5em;
      padding-left: 25px;
    }
  }

  .overlay {
    background-color: rgba(0,0,0,0.5);
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    &:focus { outline: none; }
    .dialog {
      background-color: white;
      box-shadow: 0px 0px 4px 2px #0000002b;
    }
  }

  .notification {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    &:focus { outline: none; }
    .dialog {
      border-radius: 10px;
      color: white;
      .close { color: white;}
      font-size: 16px;
      margin-bottom: 70px;
      width: 500px;
      padding: 30px;
      &.success { background-color: green;}
      &.info { background-color: blue;}
      &.warning { background-color: yellow; color: black; .close { color: black;}}
      &.error { background-color: red;}
    }
  }


}

// ::selection { color: #97A7B5; background-color: #444; }

.context-menu {
  position: absolute;
  box-shadow: 0px 2px 10px #999999;
  top: 100px;
  left: 100px;
  background-color: white;
  z-index: 4;
  padding: 5px;
  border-radius: 10px;

  .context-menu-item {
    background-color: white;
    padding: 6px 10px 5px 10px;
    min-width: 200px;
    min-height: 24px;
    cursor: pointer;
    font-size: .8em;
    display: flex;
    align-items: center;
    position: relative;

    .shortcut {
      margin-left: auto;
      margin-right: 0;
      color: gray;
    }

    &.header {
      font-weight: bold;
    }
    &.disabled {
      color: #999999;
      pointer-events: none;
      cursor: default;
      .shortcut {
        color: #999999;
      }
    }
    &:hover {
      background: #dfdfdf;
      color: black;
    }
    &:active {
      background: #dfdfdf;
      color: #000066;
    }
    .context-menu-subitem {
      margin-left: auto;
      margin-right: 0;
      &::before {
        content: '>';
        color: silver;
      }
    }
    .context-menu-submenu {
      position: absolute;
      font-size: 1rem;
      padding: 5px;
      background: white;
      z-index: 4;

      display: none;
    }
    &:hover .context-menu-submenu {
      display: block;
    }
    &.from-left {
      .context-menu-submenu {
        left: calc(100% + 0px);
        border-radius: 0 10px 10px 0;
        box-shadow: 10px 2px 10px -9px #999999;
        border-left: 1px dotted silver;
      }
      .context-menu-subitem::before {
        content: '>';
        color: black;
      }
    }
    &.from-top .context-menu-submenu {
      top: -4px;
    }
    &.from-right {
      .context-menu-submenu {
        right: calc(100% + 0px);
        border-radius: 10px 0 0 10px;
        box-shadow: -10px 2px 10px -9px #999999;
        border-right: 1px dotted silver;
      }
      .context-menu-subitem::before {
        content: '<';
        color: black;
      }
    }
    &.from-bottom .context-menu-submenu {
      bottom: -4px;
    }
  }

  .separator {
    width: 100%;
    height: 1px;
    background: #CCCCCC;
    margin: 0;
  }
}

#portal-root form.form.cm-form {
  max-width: 600px;
  font-size: 1em;

  &.prompt {
    display: grid;
    grid-template-columns: 1fr 60px;
    gap: 10px;
    
    span {
      display: block;

      &:first-child {
        grid-column: 1 / 3;
      }
    }
  }
}
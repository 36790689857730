.horizontal-ruler-wrapper {
  overflow: hidden;
  background-color: white;
  box-shadow: 0px 0px 4px 2px #0000002b;
  z-index: 1;
  position: absolute;
  height: 35px;
  bottom: 20px;
  left: 20px;
  right: 20px;
  margin: 0;
  border-radius: 5px;
  font-size: 0.8em;
  // pointer-events: none;

  .horizontal-ruler {
    margin: 0px;
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: 1fr;
    position: relative;
    color: #3C3C3C;

    .horizontal-ruler-entry {
      display: flex;
      justify-content: center;
      height: 35px;
      background: #F4F6F8;

      .cell {
        padding: 2px;
        border-radius: 5px;
        align-self: center;
        text-align: center;
        transform: rotate(-90deg);
      }
      &.highlighted {
        background: #EAEDF0;
      }
    }
  }
}

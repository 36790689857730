.toolbox {
    user-select: none;
    gap: 12px;
    padding: 15px 15px;
    border-radius: 5px;
    box-shadow: 1px 1px 5px 1px #0000002b;
    background-color: white;
    color: #74838C;

    display: flex;
    flex-flow: row wrap;

    justify-content: space-around;

    .tool {
      border-radius: 5px;
      padding: 5px;
      flex: 1 0 35px;
      height: 35px;
      margin: 0;
      background: transparent no-repeat center;
      position: relative;
      transition: box-shadow 0.1s ease;
      cursor: pointer;

      &:hover {
        box-shadow: 0 0 0px 1px #97a7b550;
        &:before { border:1px solid #97a7b580; }
      }

      &:active, &.active {
        box-shadow: 0 0 0px 1px #97a7b580;
        &:before { border:1px solid #97a7b550; }
      }

      &:before {
        content: '';
        position: absolute;
        top: 2px;
        left: 2px;
        bottom: 2px;
        right: 2px;
        border-radius: 5px;
        border: 1px solid transparent;
        transition: border-color 0.1s ease;
      }

      &.move      { background-image: url(src/assets/tool-move.svg);      }
      &.selection { background-image: url(src/assets/tool-selection.svg); }
      &.pencil    { background-image: url(src/assets/tool-pencil.svg);    }
      &.line      { background-image: url(src/assets/tool-line.svg);      }
      &.rectangle { background-image: url(src/assets/tool-rectangle.svg); }
      &.image     { background-image: url(src/assets/tool-image.svg);     }
    }
  }
